@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';
.admin-logo {
  padding: 28px 0;
  @include bp(lg-max) {
    padding: 58px 0 20px;
  }
}
.left-menu {
  padding-bottom: 110px;
  .MuiTab-root { 
    p {
      font-size: 12px !important;
    }
  }
}
.bottom-menu {
  flex-wrap: wrap;

  h2 {
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    color: $primary;
    font-family: 'Poppins';
    padding-bottom: 8px;
    opacity: 0.6;
    letter-spacing: 0.1em;
  }

  .menu-item {
    display: flex;

    li {
      border: 1px solid $white_gray;
      border-radius: 54px;
      padding: 4px;
      font-weight: 600;
      font-size: 10px;
      line-height: 1;
      font-family: 'Poppins';
      max-width: 112px;
      min-width: 104px;
      word-break: break-word;
      width: 100%;
      color: $primary;
      opacity: 1;
      display: inline-flex;
      align-items: center;
      margin-right: 15px;

      img {
        margin-right: 4px;
      }
    }
  }
}