@import '../../../../assets/scss/colors.scss';
@import '../../../../assets/scss/break-points.scss';
.detail-block {
    padding: 28px 48px;
    @include bp(xlg-max) {
      padding: 15px;
    }
    .detail-label {
      font-weight: 600;
      opacity: 0.4;
      font-size: 12px;
      display: block;
      text-transform: uppercase;
      margin-bottom: 3px;
    }
  }
  .sidebar-info-wrap {
    span,p {
      color: $primary;
    }
    .gray-btn {
      box-shadow: none;
      font-family: 'Poppins', sans-serif;
    }
  }