@import "../../../../assets/scss/colors.scss";
@import "../../../../assets/scss/break-points.scss";

.admin-profile-wrapper {
  padding: 5.3% 3% 30px 8.2%;

  .MuiFormControl-root {
    label {
      font-size: 10px !important;
      text-transform: uppercase;
      color: $primary  !important;
      opacity: 0.6;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    }

    input {
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      color: $primary;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    textarea {
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      color: $primary;
    }
  }

  @include bp(xlg-max) {
    padding: 20px 20px 0;
  }

  @include bp(sm-max) {
    padding: 15px;
  }

  .form-item-wrap {
    @include bp(xsm-max) {
      flex-wrap: wrap;
    }

    >span,
    .MuiTextField-root:first-child {
      padding-right: 10px;
      max-width: 41%;
      flex: 0 0 41%;
      font-size: 14px;
      color: $primary;

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 0;
        margin-bottom: 10px;
      }
    }

    >div {
      max-width: 59%;
      flex: 0 0 59%;

      @include bp(xsm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        justify-content: flex-start !important;
      }
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .analysis-tab-heading {
    margin-bottom: 50px;

    @include bp(sm-max) {
      margin-bottom: 20px;
    }

    >div {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .company-two-col-block {
    @include bp(sm-max) {
      flex-wrap: wrap;
    }

    .left-form-block {
      max-width: 65%;
      flex: 0 0 65%;
      padding-right: 40px;

      @include bp(xlg-max) {
        padding-right: 20px;
      }

      @include bp(sm-max) {
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 0;
        margin-top: 20px;
        order: 2;
      }
    }

    .right-image-block {
      max-width: 35%;
      flex: 0 0 35%;

      .inner-image-block {
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        height: 284px;

        @include bp(sm-max) {
          padding-bottom: 109.5%;
          height: auto;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border-radius: 12px;
          object-fit: contain;
        }
      }
    }
  }

  .profile-range-wrapper {
    @include bp(sm-max) {
      padding-right: 36px;
    }

    .MuiSlider-markLabel {
      font-family: 'Poppins';
      @include bp(sm-max) {
        text-align: center;
        transform: rotate(340deg);
        margin-left: -20px;
        font-size: 10px;
      }
    }

    .MuiSlider-markLabelActive {
      color: #111D43;
    }

    .rangeslider-wrapper {
      +.rangeslider-wrapper {
        margin-top: 25px;
      }

      label {
        font-size: 12px;
        color: $primary;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        margin-bottom: 10px;
        opacity: 0.4;
      }

      .MuiSlider-root {
        height: 1px;
        color: $primary;
      }

      .MuiSlider-track {
        border-bottom: 0;
      }

      .MuiSlider-thumbColorPrimary {
        height: 16px;
        width: 16px;
      }

      .rangeSlider {
        // padding-left: 8px;
        padding-right: 8px;
        width: 150% !important;
        @include bp(sm-max) {
          width: 100% !important;
          padding-right: 28px;
        }
      }
    }

    .MuiSlider-valueLabel:first-child {
      span {
        height: 0 !important;
        transform: rotate(0deg) translateX(-35%) translateY(5px) !important;
        background-color: white !important;
      }
    }
  }
}