@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';


.admin-dashboard-wrapper {
  overflow: hidden;

  .left-col {
    max-width: 140px;
    flex: 0 0 140px;
    display: flex;
    flex-direction: column;
    @include bp(xlg-max) {
      max-width: 110px;
      flex: 0 0 110px;
    }
    @include bp(lg-max) {
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      left: -140px;
      max-width: 140px;
      width: 140px;
      flex: 0 0 140px;
      transition: all 0.5s;
      z-index: 5;
      .mobile-menu-open & {
        left: 0;
      }
    }
    .MuiTabs-scrollButtons  {
      display: none;
    }
    .MuiTabs-scroller {
      @media(max-height:800px) {
        max-height: calc(100% - 50px);
      }
    }
    .MuiTabs-scroller .MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
      @media(min-height:800px) {
        justify-content: space-evenly;
        // height: 100%;
      }
      
    }
  }

  .right-col {
    max-width: calc(100% - 140px);
    flex: 0 0 calc(100% - 140px);
    @include bp(xlg-max) {
      max-width: calc(100% - 110px);
      flex: 0 0 calc(100% - 110px);
    }
    @include bp(lg-max) { 
      max-width: 100%;
      flex: 0 0 100%;
    }
    .admin-proptech-tab {
      @include bp(xlg-max) { 
        padding: 25px !important;
      }
      @include bp(lg-max) {
        padding: 16px !important;
      }
    }
  }


  .admin-left-wrapper {
    // overflow: auto;
    width: 100%;
    max-width: 160px;
    height: 100%;

    .logo-left {
      // padding: 40px 0 50px;
      text-align: center;
      // position: absolute;
      left: 0;
      right: 0;
      top: 0;
      background-color: $primary;

      img {
        margin: 0 auto;
      }
    }

    .left-inner {
      position: relative;
      padding-top: 140px;
    }
  }
}