@import '../../../../assets/scss/colors.scss';
@import '../../../../assets/scss/break-points.scss';

.permission-tab {
    width: 100%;
    height: 100%;
    padding: 20px 20px 0;
    box-sizing: border-box;

    .main-container {
        width: 100%;
        display: flex;
        column-gap: 8px;
        overflow-y: auto !important;

        @include bp(xsm-max) {
            flex-direction: column;
        }

        .left-column {
            width: 50%;

            @include bp(xsm-max) {
                width: 100%;
            }
        }

        .right-column {
            width: 50%;

            @include bp(xsm-max) {
                width: 100%;
            }
        }

        .heading {
            padding: 8px;
            font-weight: 500;
            text-align: center;
        }
    }
}


.proptech-tab-heading {
    padding: 27px 23px;

    @include bp(xlg-max) {
        padding: 15px;
    }

    .eye-icon {
        line-height: 0;
        position: relative;
        top: 5px;
        min-width: 24px;
        margin-left: 10px;
    }
    .middle-title-text {
        max-width: 40%;
        flex: 0 0 40%;
        padding-right: 15px;
        @include bp(desk-max) {
            max-width: calc(100% - 55px);
            flex: 0 0 calc(100% - 55px);
            padding-right: 0;
        }
        @include bp(xlg-max) {
            max-width: calc(100% - 61px);
            flex: 0 0 calc(100% - 61px);
            padding-right: 0;
        }

    }
    .tag-container {
        max-width: calc(60% - 55px);
        flex: 0 0 calc(60% - 55px);
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-bottom: -10px;
        @include bp(desk-max) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-top: 10px;
            padding-left: 55px;
            justify-content: flex-start;
        }
        @include bp(xlg-max) { 
            padding-left: 61px;
        }
        .edit-tag-container {
            margin-bottom: 10px;
        }
    }
    .proptech-heading-btn {
        button {
            margin-top: 10px;
        }
    }
}

.proptech-card-border {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(231, 232, 236, 0.4);

    .image-container {
        width: 40px;
        min-width: 40px;
        height: 40px;
        cursor: pointer;
        margin-right: 15px;
        border-radius: 9999px;

        @include bp(xlg-max) {
            height: 46px;
            width: 46px;
            min-width: 46px;
        }

        .logo {
            width: 100%;
            height: 100%;
            border-radius: 9999px;
            background-color: #70778E;
            object-fit: cover;
        }
    }

    .title {
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        color: #111D43;
    }

    .label {
        display: flex !important;
        align-items: center;

        @include bp(xlg-max) {
            min-width: 104px;
            flex-direction: row;
        }

        .header {
            margin-right: 4px;
            font-weight: 500;
            line-height: 20px;
            font-style: normal;
            font-size: 11px;
            opacity: 0.6;
        }

        .value {
            font-weight: 600;
            line-height: 12px;
            font-style: normal;
            text-transform: uppercase;
            font-size: 11px;
        }
    }

    .edit-tag-container {
        margin-right: 8px;
        border-radius: 12px;
        border-style: solid;
        box-sizing: border-box;
        padding: 8px;
        background-color: #FFD84F;
    }

    .edit-tag-button {
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: #000000;
    }
}

.tag-container {
    display: flex;
}