@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

@import './assets/scss/colors.scss';
@import './assets/scss/break-points.scss';

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.mobile-menu-open {
  @include bp(md-max) {
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.para-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: $primary;
  opacity: 0.6;
  font-family: 'Poppins';
  padding: 24px 0;
  max-width: 510px;

  @include bp(sm-max) {
    padding: 15px;
    max-width: 100%;
  }
}
.left-wrapper {
  position: fixed;
  overflow: auto;
  left: 0;
  width: 100%;
  max-width: 240px;
  height: calc(100% + 3px);
  z-index: 10;
  transition: all 0.5s;
  top: 0;

  @include bp(lg-max) {
    left: -240px;
    max-height: 100%;
    overflow: hidden;
    // padding-bottom: 20px;

    .mobile-menu-open & {
      left: 0;
    }
  }

  .logo-left {
    padding: 40px 0 50px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: $primary;

    img {
      margin: 0 auto;

      @include bp(lg-max) {
        max-width: 140px;
        margin-left: 15px;
      }
    }

    @include bp(lg-max) {
      padding: 20px 0;
    }
  }

  .left-inner {
    position: relative;
    padding-top: 140px;

    @include bp(lg-max) {
      padding-top: 70px;
      overflow: auto;
    }
  }
}

.dashboard-wrapper {
  overflow: hidden;

  .left-col {
    max-width: 240px;
    flex: 0 0 240px;

    @include bp(lg-max) {
      max-width: inherit;
      flex: inherit;
    }
  }

  .right-col {
    max-width: calc(100% - 240px);
    flex: 0 0 calc(100% - 240px);
    overflow: auto;

    @include bp(lg-max) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

.left-menu {
  .menu-item {
    position: relative;
    opacity: 0.4;
  }

  .Mui-selected {
    .menu-item {
      opacity: 1;
    }
  }

  .MuiTab-root {
    text-transform: none;
    margin: 11px 0;
    padding: 0;

    @include bp(lg-max) {
      min-height: 40px;
      margin: 5px 0;

      .text-base {
        font-size: 14px !important;
      }
    }
  }

  .MuiTabs-indicator {
    border-radius: 0px 2px 2px 0px;
    width: 4px;
    background: $secondary;
    right: auto;
    left: 0;
  }
}

.progress-area {
  width: 40px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .data {
    font-weight: 600;
    font-size: 20px;
    color: $secondary;
    position: absolute;
    font-family: 'Poppins';
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiCircularProgress-root {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 50%;
    color: $secondary;
  }
}