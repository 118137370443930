@import '../../../assets/scss/colors.scss';
@import '../../../assets/scss/break-points.scss';

.admin-proptech-tab-mi {
    .left-block {
        max-width: 400px;
        flex: 0 0 400px;

        @include bp(xlg-max) {
            max-width: 300px;
            flex: 0 0 300px;
        }

        @include bp(md-max) {
            max-width: 100%;
            flex: 0 0 100%;
            height: auto !important;
        }

        .proptech-tab-heading {
            padding: 27px 23px;

            @include bp(xlg-max) {
                padding: 15px;
            }

            .eye-icon {
                line-height: 0;
                position: relative;
                top: 5px;
                min-width: 24px;
                margin-left: 10px;
            }

            .proptech-heading-btn {
                button {
                    margin-top: 10px;
                }
            }
        }

    }

    .right-block {
        max-width: calc(100% - 400px);
        flex: 0 0 calc(100% - 400px);

        @include bp(xlg-max) {
            max-width: calc(100% - 300px);
            flex: 0 0 calc(100% - 300px);
        }

        @include bp(md-max) {
            max-width: 100%;
            flex: 0 0 100%;
        }

        .right-top-heading-admin {
            padding: 18px 34px 18px 51px;
            min-height: 80px;

            @include bp(desk-max) {
                flex-wrap: wrap;
                padding: 18px 25px;
                // justify-content: flex-end !important;
            }

            @include bp(xlg-max) {
                padding: 15px 20px;
            }

            @include bp(md-max) {
                flex-wrap: nowrap;
            }

            @include bp(sm-max) {
                padding: 15px;
            }

            @include bp(sm-max) {
                flex-direction: column;
                // align-items: flex-end !important;
            }

            .btn {
                padding: 0 10px;
                min-width: 120px;

                &:last-child {
                    margin-right: 0 !important;
                }

                @include bp(md-max) {
                    min-width: 90px;
                }
            }

            .top-heading-listing-admin {
                @include bp(desk-max) {
                    // max-width: 100%;
                    // flex: 0 0 100%;
                    // margin-bottom: 10px;

                }

                @include bp(md-max) {
                    max-width: inherit;
                    flex: inherit;
                    margin-bottom: 0;
                }

                @include bp(sm-max) {
                    margin-bottom: 20px;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                >div {
                    &:not(:last-child) {
                        margin-right: 20px;

                        @include bp(xsm-max) {
                            margin-right: 10px;
                        }
                    }
                }
            }

        }
    }
}

.snapshot-block,
.answer-block {
    padding: 5.3% 8.2% 30px;
    box-sizing: border-box;

    @include bp(xlg-max) {
        padding: 20px;
    }

    @include bp(sm-max) {
        padding: 15px;
    }

    .heading-block {
        margin-bottom: 63px;

        @include bp(xlg-max) {
            margin-bottom: 25px;
        }

        @include bp(sm-max) {
            flex-direction: column;
            align-items: flex-start !important;
            margin-bottom: 15px;

            h3 {
                margin-bottom: 10px;
            }
        }
    }

    h3 {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 16px;
        line-height: 22px
    }

    .content-wrapper {
        margin-bottom: 45px;

        @include bp(xlg-max) {
            margin-bottom: 35px;
        }

        @include bp(xsm-max) {
            flex-wrap: wrap;
        }

        p {
            line-height: 1.6 !important;
        }

        .left-block {
            max-width: 29%;
            flex: 0 0 29%;

            @include bp(xlg-max) {
                max-width: 33%;
                flex: 0 0 33%;
            }

            @include bp(xsm-max) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }

        .right-block {
            max-width: 71%;
            flex: 0 0 71%;
            padding-left: 14%;

            @include bp(xlg-max) {
                max-width: 67%;
                flex: 0 0 67%;
                padding-left: 30px;
            }

            @include bp(xsm-max) {
                max-width: 100%;
                flex: 0 0 100%;
                padding-left: 0;
                padding-top: 10px;
            }
        }
    }
}