@import "../../../../assets/scss/colors.scss";
@import "../../../../assets/scss/break-points.scss";

.MuiSlider-markLabel {
    font-family: "Poppins";
    @include bp(sm-max) {
        text-align: center;
        transform: rotate(340deg);
        margin-left: -20px;
        font-size: 10px;
    }
}

.MuiSlider-markLabelActive {
    color: #111d43;
}

.rangeslider-wrapper {
    + .rangeslider-wrapper {
        margin-top: 25px;
    }

    label {
        font-size: 12px;
        color: $primary;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        margin-bottom: 10px;
        opacity: 0.4;
    }

    .MuiSlider-root {
        height: 1px;
        color: $primary;
    }

    .MuiSlider-track {
        border-bottom: 0;
    }

    .MuiSlider-thumbColorPrimary {
        height: 16px;
        width: 16px;
    }

    .rangeSlider {
        // padding-left: 8px;
        padding-right: 8px;
        width: 100% !important;
        @include bp(sm-max) {
            width: 100% !important;
            padding-right: 28px;
        }
    }
}

.MuiSlider-valueLabel:first-child {
    span {
        height: 0 !important;
        transform: rotate(0deg) translateX(-35%) translateY(5px) !important;
        background-color: white !important;
    }
}
